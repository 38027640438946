import Vue from 'vue';
import App from './App.vue';
// import Index from './Index.vue'
import store from '@/Vue-Store';
import router from '@/Vue-Router';
import '@/Vue-Axios';
import '@/Vue-Axios/api';
import '@/ant-desgin-vue';
import '@/components';

import less from 'less'
Vue.use(less);

//解决IE浏览器ES6的问题
import 'babel-polyfill';

// 调试工具
// import Vconsole from 'vconsole';
// if (window.location.host.indexOf("192.168.") === 0) new Vconsole();

// 复制文本到剪切栏功能
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false;

// Jquery
import $ from 'jquery' //在需要使用的页面中
Vue.prototype.$ = $;
// 定时器储存
Vue.prototype.$IntervalID = {};
// 域名
const host = 'http://app.hm-pool.com';
Vue.prototype.$host = host;
// 图片、视频库引入地址
Vue.prototype.$public = `${host}/public/`;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#App');