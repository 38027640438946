<template>
  <div id="Footer">
    <div id="footer-button" ref="footer-button-fixed">
      <a-row>
        <a-col
          v-for="(item, i) in footerMenu"
          :span="24 / footerMenu.length"
          :key="i"
          :class="
            $route.matched[0] && $route.matched[0].name === item.name
              ? 'this'
              : ''
          "
        >
          <router-link :to="{ name: item.name }">
            <img :src="`${$public}/images/footer/footer-white-${i + 1}.png`" />
            <p v-if="item.name !== 'Home'">{{ item.meta.title }}</p>
            <p v-else>首页</p>
          </router-link>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
    header: String,
  },
  beforeCreate() {},
  data() {
    return {
      window: window,
      footerMenu: [],
    };
  },
  methods: {
    get_footer_menu() {
      const routes = this.$router.options.routes;
      this.footerMenu = [];
      for (let i in routes)
        if (routes[i].meta && routes[i].meta.menuLevel === 0)
          this.footerMenu.push(routes[i]);
    },
  },
  created() {
    this.get_footer_menu();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#Footer {
  width: 100%;
  box-shadow: 0px 0px 0.18rem 0px rgba(0, 0, 0, 0.3);
  // @footerButton: 1.2rem;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
  }
  // margin-bottom: @footerButton;

  > #footer-test {
    position: fixed;
    left: 0;
    bottom: 1.5rem;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    padding: 1em;
    line-height: 1.2em;
    //: 0.2rem;
  }

  > #footer-button {
    // //: @footerButton / 5.5;
    // padding-top: 0.5rem;
    font-size: 13.5px;

    > .ant-row {
      // position: fixed;
      // bottom: 0;
      // left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      background-color: rgba(31, 38, 82, 0.8);
      overflow: hidden;

      > .ant-col {
        text-align: center;
        padding: 0.5em 0.75em;
        padding-top: 1em;

        > a {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-content: center;
          align-items: center;
          flex-wrap: nowrap;
          width: 100%;
          // height: 100%;

          > * {
            display: block;
            filter: drop-shadow(1px 1px 0 rgba(94, 104, 171, 1));
          }

          > img {
            height: 1.3em;
            margin-bottom: 0.5em;
          }

          > p {
            height: 1em;
            line-height: 1em;
          }
        }

        &.this {
          margin-top: -0.3em;
          border-bottom: 0.2em solid rgba(94, 104, 171, 1);
          position: relative;
          overflow: hidden;
          // color: rgba(94, 104, 171, 1);

          > a {
            color: inherit;

            > * {
              // filter: drop-shadow(3px 3px 0 rgba(94, 104, 171, 1));
            }
          }

          &::before {
            content: "";
            position: absolute;
            top: 30%;
            left: 50%;
            width: 200%;
            height: 150%;
            background-image: radial-gradient(
              rgba(48, 52, 99, 0.3),
              rgba(255, 255, 255, 0),
              rgba(94, 104, 171, 1)
            );
            transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%); /* Opera */
            -ms-transform: translate(-50%, -50%); /* IE 9 */
            -moz-transform: translate(-50%, -50%); /* Firefox */
            -webkit-transform: translate(-50%, -50%); /* Safari 和 Chrome */
          }

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 0.3em;
            // background: linear-gradient(rgba(48, 52, 99, 0.3), #3d447b);
          }
        }
      }
    }
  }
}
</style>
