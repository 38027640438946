import Vue from 'vue';
import axios from 'axios';
import router from '@/Vue-Router';
import {
  Modal,
  message
} from 'ant-design-vue'
// 初始化全局设置
message.config({
  top: `100px`,
  duration: 5,
  maxCount: 10,
});

// axios默认配置
axios.defaults.timeout = 30000; // 超时时间

const host = window.location.host;


if (host.indexOf("192.168") === 0)
  axios.defaults.baseURL = sessionStorage.getItem('baseUrl') ?
  sessionStorage.getItem('baseUrl') :
  'http://192.168.0.58:9999'; // 测试地址
else if (host.indexOf("beta.hm-pool.com") === 0)
  axios.defaults.baseURL = 'http://bug.hm-pool.com'; // 测试地址
else if (host.indexOf("app.hm-pool.com") === 0 || host.indexOf("hm-pool"))
  axios.defaults.baseURL = 'http://api.hm-pool.com'; // 正式地址

const api_version_number = '/' + 'v2' + '/'; // 版本号
axios.version = api_version_number;

//整理数据
axios.defaults.transformRequest = function (data) {
  return data;
};

// 路由请求拦截
// http request 拦截器
axios.interceptors.request.use(
  config => {
    // console.log('axios.interceptors.request: ', config);

    // 不需要表头携带token的api
    const a = -1 === [
      'login',
      'logout',
      'register',
      'send-verify-code',
      'sys-currencys'
    ].indexOf(config.url);
    const b = new RegExp("^[a-zA-Z/]").test(config.url);

    // 请求方式 携带 token 以及 插入 版本，并且判断是否是本地请求
    if (config.params && config.params.type === 'public') {
      config.baseURL = window.location.origin;
    } else if (!b) {
      config.baseURL = window.location.origin;
    } else if (a) {
      // config.baseURL = api_version_number;
      config.url = api_version_number + config.url;
      // 表头设置token
      const token =
        localStorage.getItem("user") ?
        JSON.parse(localStorage.getItem("user")).token :
        null;
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }

    // get 方式携带 时间信息
    if (config.method === 'get') {
      if (!config.params) config.params = {}
      const time = new Date();
      const a = time.getHours() + ':' +
        time.getMinutes() + ':' +
        time.getSeconds() + ':' +
        time.getMilliseconds();
      config.params.nowTime = a;
    }

    return config;
  },
  error => {
    return Promise.reject(error.response);
  }
);

// 路由响应拦截
// http response 拦截器
axios.interceptors.response.use(
  res => {
    // console.log(res)
    if (res.status === 200) {
      if (res.data.code === 401) {
        sessionStorage.removeItem("user");
        localStorage.removeItem("user");
        // console.log(res)
        if (res.config.type === 'change-password')
          message.success("密码修改成功，请重新登录");
        else if (res.config.url === 'logout')
          message.success("退出成功");
        else
          message.warning(res.data.message);

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (res.data.code === 10504) {
        Modal.warning({
          title: `您还没有进行实名认证`,
          icon: "warning",
          okText: "立即设置",
          onOk() {
            router.push({
              name: "Real-Name-Authentication",
            });
          },
        });
      } else if (res.data.code && res.data.code !== 0) {
        message.destroy();
        message.config({
          duration: 10,
        });

        message.warning(res.data.code);
        message.warning(res.data.message);
      }
      return res.data;
    } else {
      // 请求错误
      console.group('---- error ----------------')
      console.log('    status:', res.status);
      console.log('statusText:', res.statusText);
      console.log('  response:', res);
      console.groupEnd()
      message.error(res.statusText);
    }
  },
  error => {
    message.destroy();
    if (error) {
      if (error.response) {
        const err = error.response;
        // 请求错误，判断请求错误类型
        if (err.status === 401) {
          message.error(err.data.message);
          sessionStorage.removeItem("user");
          localStorage.removeItem("user");
          // 刷新页面
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (err.status === 404 && err.config.params.type === 'public') {
          console.group('文件不存在');
          console.log('url: ', err.config.url);
          console.groupEnd();
        } else {
          message.error(err.status);
          message.error(err.message);
        }
      } else if (error.response === undefined) {
        if (-1 < error.config.baseURL.indexOf("192.168")) {
          sessionStorage.setItem('baseUrl', 'http://bug.ky-ipfs.com');
          axios.defaults.baseURL = sessionStorage.getItem('baseUrl');
          window.location.reload();
        } else {
          message.destroy();
          message.error('链接服务器失败！');
          message.error('请联系管理员');
        }

        if (error.config.url !== 'login') {
          console.log('链接服务器失败');
        } else if (error.config.url === 'logout') {
          sessionStorage.removeItem("user");
          localStorage.removeItem("user");
        }

      } else {
        console.log(error.code);
        console.log(error.message);
      }
    }
  }
);

//让ajax携带cookie
axios.defaults.withCredentials = true;

// aixos
let Base64 = require("js-base64").Base64;
import qs from "qs";
axios.qs = qs;
axios.Base64 = Base64;

// axios 请求过程中的按键 禁用 与 解禁
axios.btnPower = (flg = true, delay = 300) => {
  const btns = document.getElementsByTagName('button');
  if (flg) btns.forEach(dom => dom.setAttribute('disabled', 'disabled'));
  else setTimeout(() => {
    btns.forEach(dom => dom.removeAttribute('disabled'))
  }, delay);
}

Vue.prototype.$axios = axios;

export default axios;