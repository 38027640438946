<template>
  <a-config-provider :locale="$language.zhCN">
    <div id="App" :class="`${$route.matched[0] ? $route.matched[0].name : ''}`">
      <template>
        <template v-if="$route.matched[0] && $route.matched[0].name === 'User'">
          <router-view />
        </template>
        <template v-else>
          <!-- 页头 -->
          <Header />
          <!-- 主体 -->
          <div id="Body">
            <!-- <keep-alive include="body"> -->
            <router-view />
            <!-- </keep-alive> -->
          </div>
          <!-- 页尾 -->
          <Footer />
        </template>
      </template>
    </div>
  </a-config-provider>
</template>

<script>
import Vue from "vue";
import { ConfigProvider } from "ant-design-vue";
Vue.use(ConfigProvider);

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import { annunciate } from "@/components";

let _this = {};
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data: function () {
    _this = this;
    _this;
    return {};
  },
  methods: {
    image_error() {
      const images = document.getElementsByTagName("img");
      const ErrorSrc = `${this.$public}images/error/error-image.png`;
      images.forEach((dom) => {
        const errSrc = dom.getAttribute("errSrc");
        if (errSrc === null) return;
        // 图片错误
        dom.addEventListener("error", () => {
          dom.src = errSrc ? errSrc : ErrorSrc;

          if (dom.getAttribute("error-disabled") !== null)
            dom.style.display = "none";
        });
      });
    },
  },
  created() {
    // 页面前后台切换 根据APP进入后台时间长短判断是否需要刷新页面
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "visible") {
        // console.log("后台切换前台");
        // 后台切换前台
        let newDate = new Date().valueOf(); // 当前时间戳
        let oldDate = parseInt(
          localStorage.getItem("enterSystemBackgroundTime")
        ); // 进入后台时的时间戳
        let a = newDate - oldDate; //进入后台的时间与当前时间时间差
        let b = 30 * 60 * 1000; // 指定时间，时间差超过指定时间，刷新页面
        if (b < a) {
          window.location.reload();
        }

        annunciate();
      } else if (document.visibilityState == "hidden") {
        // console.log("前台切换后台");
        // 前台切换后台
        localStorage.setItem("enterSystemBackgroundTime", new Date().valueOf());
      }
    });

    this.image_error();
  },
  BeforeUpdate() {
    this.image_error();
  },
  updated() {
    // console.log("updated");
    this.image_error();
  },
};
</script>

<style lang="less">
html {
  body {
    background-color: #303463 !important;
  }
}
</style>
<style lang="less">
#App {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  font-size: 16px;

  &:not(.User) {
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: stretch;

    > div[id] {
      flex: 0 0 auto;

      &#Body {
        flex: 0 1 auto;
        @paddingTB: 1em;
        padding-top: @paddingTB;
        @paddingRL: 1em;
        padding-left: @paddingRL;
        padding-right: @paddingRL;
        // margin: 0 auto;
        width: 100%;
        height: 100%;
        // max-width: 768px;
        overflow-x: hidden;
        overflow-y: auto;

        > div[id] {
          padding-bottom: @paddingTB;
          min-height: 100%;
        }

        > div [class*="-body"] {
          width: 100%;
          max-width: 768px;
          margin: 0 auto;
        }
      }
    }
  }

  &.User {
    align-items: center;

    > #User {
      max-width: 768px;
      margin: 0 auto;
    }
  }
}

#enter_into_await {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  .enter_into_shade {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .enter_into_image {
    position: absolute;
    z-index: -1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .enter_into_video {
    position: absolute;
    z-index: -999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    &::-webkit-media-controls-enclosure {
      /*禁用播放器控制栏的样式*/
      display: none !important;
    }
    &::-webkit-media-controls {
      display: none !important;
    }
  }

  .shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    // background: rgba(255, 0, 0, 0.3);
  }
}
.xl-chrome-ext-bar {
  display: none;
}

#__vconsole {
  #__vc_log_default {
    background-color: rgba(0, 0, 0, 1);
  }
}
</style>
