import Vue from 'vue'
const h = new Vue().$createElement;
const _this = Vue.prototype;

_this.$Common = {
  timestampToString(timestamp, format = 'yy/mm/dd') {
    timestamp = parseInt(timestamp);


    format
    return timestamp;
  },
  Countdown(count = 0, fun = {}) {
    typeof fun.info === 'function' && fun.info(count);
    clearInterval(a);
    const a = setInterval(() => {
      typeof fun.before === 'function' && fun.before(count);
      if (0 < count--) return;
      clearInterval(a);
      typeof fun.after === 'function' && fun.after(count);
    }, 1000);
  }
};

_this.$Fun = () => {
  console.log(this)
  return {

  }
}

_this.$Modal = [];
import {
  Modal
} from "ant-design-vue";

// 重要提醒公告

export const riskAlert = (a = true) => {
  if (_this.$Modal['Risk-Alert']) _this.$Modal['Risk-Alert'].destroy();
  if (!a) return;
  _this.$Modal['Risk-Alert'] = Modal.warning({
    class: `Risk-Alert Risk-Alert-${new Date().valueOf()}`,
    title: h('span', {
      style: {
        fontSize: '20px!important',
        lineHeight: '21px',
        color: '#faad14'
      },
      domProps: {
        innerHTML: '重要提醒：'
      },
    }),
    
    /*
    content: function () {
        //注释开始
      const names = this.class.split(' ');
      let name = ''
      for (let i in names)
        if (names[i].indexOf('Risk-Alert') != -1)
          name = names[i];

      const b = setInterval(() => {
        let dom = document.getElementsByClassName(name)[0];
        if (!dom) return false;
        clearInterval(b);

        const btn = dom
          .getElementsByClassName("ant-modal-confirm-btns")[0]
          .getElementsByClassName("ant-btn")[0];
        const btnTxt = btn.getElementsByTagName("span")[0];
        const content = dom.getElementsByClassName(
          "ant-modal-confirm-content"
        )[0];

        Object.assign(content.style, {
          marginTop: "1em",
          marginLeft: "0",
        });
        
        // 确认阅读后  (${Countdown}s) (${Countdown}s) 
        // btn.setAttribute("disabled", "disabled");
        btn.setAttribute("", "");
        _this.$Common.Countdown(10, {
        info: (Countdown) =>
            (btnTxt.innerHTML = `确认阅读`),
        before: (Countdown) =>
            (btnTxt.innerHTML = `确认阅读 `),
        after: () => {
            btnTxt.innerHTML = "确认阅读";
            btn.removeAttribute("disabled");
          },
        });
      }, 10);
     
      return h(
        'div',
        [
          h('p', '《关于部分交易所清退中国大陆用户的应对问题》'),
          h('p', {
            style: {
              color: '#ddd',
              textIndent: '2em'
            }
          }, ['为保障客户数字资产安全，请大家务必于2021年12月1日前将 “HM-pool”APP内 实名认证的提币地址，修改为未被清退的交易所或冷钱包的新充值地址。（在对应的官方网站下载未被清退的交易所或冷钱包）']),
          h('p', {
            style: {
              color: '#ddd',
              textIndent: '2em'
            },
          }, [
            h('b', {
              style: {
                fontSize: '1.2em',
                color: '#ff2200'
              }
            }, ['部分交易所清退后，仍未修改实名认证中提币地址的客户，如出现提币未到账的情况，后果自负。']),
            '需要操作流程或有疑问的客户，请咨询在线客服。'
          ]),
          h('p', {
            style: {
              color: '#ddd',
              textIndent: '2em'
            }
          }, ['近期出现很多钓鱼网站和诈骗电话，确保个人资产安全，建议大家减少“HM-pool”APP 提币的频率。']),
          h('br'),
          h('div', {
            style: {
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '1em'
            }
          }, [
            h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }
            }, [
              h('p', 'HMpool'),
              h('p', '2021年11月04日')
            ])
          ])
        ]
      )
    },
    */
    
    content: function () {
      return h(
        'div',
        [
          h('p', '《关于部分交易所清退中国大陆用户的应对问题》'),
          h('p', {
            style: {
              color: '#ddd',
              textIndent: '2em'
            }
          }, ['为保障客户数字资产安全，请大家务必于2021年12月1日前将 “HM-pool”APP内 实名认证的提币地址，修改为未被清退的交易所或冷钱包的新充值地址。（在对应的官方网站下载未被清退的交易所或冷钱包）']),
          h('p', {
            style: {
              color: '#ddd',
              textIndent: '2em'
            },
          }, [
            h('b', {
              style: {
                fontSize: '1.2em',
                color: '#ff2200'
              }
            }, ['部分交易所清退后，仍未修改实名认证中提币地址的客户，如出现提币未到账的情况，后果自负。']),
            '需要操作流程或有疑问的客户，请咨询在线客服。'
          ]),
          h('p', {
            style: {
              color: '#ddd',
              textIndent: '2em'
            }
          }, ['近期出现很多钓鱼网站和诈骗电话，确保个人资产安全，建议大家减少“HM-pool”APP 提币的频率。']),
          h('br'),
          h('div', {
            style: {
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '1em'
            }
          }, [
            h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }
            }, [
              h('p', 'HMpool'),
              h('p', '2021年11月04日')
            ])
          ])
        ]
      )
    },
    okText: "我已阅读",
    centered: true, // 垂直居中展示 Modal
  });
  return _this.$Modal['Risk-Alert'];
};

// APP公告
// import Annunciate from '@/components/Annunciate'
// console.log(Annunciate);
export const annunciate = (a = true) => {
  if (_this.$Modal['Risk-Alert']) _this.$Modal['Risk-Alert'].destroy();
  if (!a) return;
  _this.$Modal['Annunciate'] = Modal.warning({
    class: `Risk-Alert Risk-Alert-${new Date().valueOf()}`,
    title: h('span', {
      style: {
        fontSize: '20px!important',
        lineHeight: '21px',
        color: '#faad14'
      },
      domProps: {
        innerHTML: '放假通知：'
      },
    }),
    content: function () {
      return h('div', {
        style: {}
      }, [
        h('p', {}, ['放假通知：']),
        h('p', {
          style: {
            textIndent: '2em'
          }
        }, [
          ' 根据《国务院办公厅关于2022年部分节假日安排的通知》，现将2022年“五一”劳动节做如下安排:'
        ]),
        h('p', {}, ['放假时间：2022年4月30日至5月4日，共5天。']),
        h('p', {}, ['1、APP提币：5/5日到账；']),
        h('p', {}, ['2、售后问题：如遇紧急问题可语音或电话联系客服。']),
        
      ])
    },
    okText: "已了解",
    centered: true, // 垂直居中展示 Modal
  });
  return _this.$Modal['Risk-Alert'];
};