import Vue from 'vue'

// 常用
import {
  Row,
  Col,
  Spin,
  Icon,
  Empty,
  Anchor,
  Input,
  Button,
  Tooltip,
  Divider,
  Checkbox
} from "ant-design-vue";
Vue
  .use(Row)
  .use(Col)
  .use(Spin)
  .use(Icon)
  .use(Empty)
  .use(Anchor)
  .use(Input)
  .use(Button)
  .use(Tooltip)
  .use(Divider)
  .use(Checkbox);

// ant design vue。 date picker 组件中文
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

import zhCN from "ant-design-vue/lib/locale-provider/zh_CN"; // 汉化
Vue.prototype.$language = {
  zhCN: zhCN
}
// ant design vue   $message 全局提示组件
import {
  message
} from 'ant-design-vue'
// 初始化全局设置
message.config({
  top: `100px`,
  duration: 5,
  maxCount: 5,
});
// 挂载

Vue.prototype.$message = message;

// ant design vue UI样式
import 'ant-design-vue/dist/antd.css';
// 引入公共文件（放在这里是为了覆盖 ant-desgin-vue的默认样式）
import './index.less'
import '@/components/css/common.less'
