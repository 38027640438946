<template>
  <div id="Header" ref="Header">
    <div class="header-fixed" ref="header-fixed">
      <!-- <router-link class="left" to v-if="this.$route.path !== '/Home'"> -->
      <div class="left">
        <router-link
          class="left goBack"
          to
          v-if="
            ($route.meta.menuLevel !== 0 &&
              $route.matched &&
              1 < $route.matched.length) ||
            $route.meta.goBack
          "
        >
          <div @click="$router.back(-1)" class="back">
            <a-icon type="rollback" />
          </div>
        </router-link>
      </div>
      <div class="title">
        <span>{{ $route.meta.title }}</span>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#Header {
  font-size: 1.3em;
  width: 100%;
  @height: 2em;
  height: @height + 1em;
  line-height: @height;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  box-shadow: 0px 0px 0.18rem 0px rgba(0, 0, 0, 0.3);

  > div.header-fixed {
    position: relative;
    width: 95%;
    height: @height;
    margin: 0 2.5%;
    background-color: rgba(48, 52, 99, 0.8);

    > * {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: nowrap;
      text-align: center;

      &:not(.title) {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;

        &.left {
          left: 0;
        }

        &.right {
          right: 0;
        }
      }
    }

    > .title {
      width: 100%;

      &.header-home {
        font-size: 1.2em;
        font-weight: bold;
      }
    }
  }
}
</style>
