import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  state: {
    //存放的键值对就是所要管理的状态
    name: 'helloVueX',
    windowWidth: window.innerWidth,
    transitionName: {
      home: "slide-go", // 页面前进后退
      mainCatalogue: 'slide-go' // 主目录前进后退
    },
    header: {
      title: 'HM pool'
    },
  },
})

export default store