let routes = [{
  path: '/',
  redirect: '/Home'
},
// Home
{
  path: '/Home',
  meta: {
    title: 'HM pool',
    menuLevel: 0
  },
  component: () => import('@/Pages/Home')
},
// Product
{
  path: '/Product',
  meta: {
    title: '产品',
    menuLevel: 0
  },
  component: () => import('@/Pages/Product'),
  children: [{
    path: '/Product-Update',
    meta: {
      title: '产品升级'
    },
    component: () => import('@/Pages/Product/Update')
  }, {
    path: '/Money-Manage-Details',
    meta: {
      title: '基金详情'
    },
    component: () => import('@/Pages/Product/Money-Manage-Details')
  }]
},
// Block
{
  path: '/Block',
  meta: {
    title: '存储池',
    menuLevel: 0
  },
  component: () => import('@/Pages/Block'),
  children: [{
    path: '/Ore-Pool-View/:id',
    meta: {
      title: '存储池详情',
      menuLevel: 0
    },
    component: () => import('@/Pages/Block/Ore-Pool/View'),
  }]
},
// My
{
  path: '/My',
  meta: {
    title: '我的',
    menuLevel: 0
  },
  component: () => import('@/Pages/My'),
  children: [
    ...[ // 我的页面 - 主菜单
      { // 我的页面 - 菜单 - 收益明细
        path: '/Stoping-Machine',
        meta: {
          title: '收益明细',
          type: 'menu',
        },
        component: () => import('@/Pages/My/Menu/Stoping-Machine')
      },
      ...[{ // 我的页面 - 我的资产
        path: '/My-Walle',
        meta: {
          title: '我的资产',
          type: 'menu',
        },
        component: () => import('@/Pages/My/Menu/Walle'),
        children: []
      }, { // 充值
        path: '/Walle-Pay/:type',
        meta: {
          title: '充币',
        },
        component: () => import('@/Pages/My/Menu/Walle/Walle-Pay'),
      }, { // 提现
        path: '/Walle-Withdraw/:type',
        meta: {
          title: '提币',
        },
        component: () => import('@/Pages/My/Menu/Walle/Walle-Withdraw'),
      }, { // 资产变更明细
        path: '/Change-Record/:type',
        meta: {
          title: '资产变更明细',
        },
        component: () => import('@/Pages/My/Menu/Walle/Walle-Change-Record'),
        // }, { // 转账
        //   path: '/Walle-Transfer-Accounts/:type',
        //   meta: {
        //     title: '转账',
        //   },
        //   component: () => import('@/Pages/My/Menu/Walle/Walle-Transfer-Accounts'),

      }],
      ...[{ // 我的页面 - 安全设置
        path: '/Safety-Setting',
        meta: {
          title: '安全设置',
          type: 'menu',
        },
        component: () => import('@/Pages/My/Menu/Safety-Setting'),
      }, { // 我的页面 - 安全设置 - 修改登录密码
        path: '/login-password',
        name: 'login-password',
        meta: {
          title: '修改登录密码',
          flag1: '登录',
          flag2: 'login',
          classify: ['setting', 'password']
        },
        component: () => import('@/Pages/My/Menu/Safety-Setting/Setting-Password')
      }, { // 我的页面 - 安全设置 - 修改支付密码
        path: '/pay-password',
        name: 'pay-password',
        meta: {
          title: '修改支付密码',
          flag1: '支付',
          flag2: 'pay',
          classify: ['setting', 'password']
        },
        component: () => import('@/Pages/My/Menu/Safety-Setting/Setting-Password')
      }, { // 我的页面 - 安全设置 - 设置实名信息
        path: '/Real-Name-Info',
        meta: {
          title: '设置实名信息',
          classify: ['setting', 'info']
        },
        component: () => import('@/Pages/My/Menu/Safety-Setting/Setting-Info')
      }, 
      { // 我的页面 - 安全设置 - 设置提币地址
        path: '/Setting-Extract-Currency-Address',
        meta: {
          title: '设置提币地址',
          classify: ['setting', 'info']
        },
        component: () => import('@/Pages/My/Menu/Safety-Setting/Setting-Extract-Currency-Address')
      }, 
      { // 实名认证
        path: '/Real-Name-Authentication',
        meta: {
          title: '实名认证',
          classify: ['setting', 'info']
        },
        component: () => import('@/Pages/My/Menu/Safety-Setting/Real-Name-Authentication')
      }, { // 实名认证 - 认证示例
        path: '/Hint-Updata',
        meta: {
          title: '实名认证 认证示例',
          // classify: ['setting', 'info']
        },
        component: () => import('@/Pages/My/Menu/Safety-Setting/Real-Name-Authentication/Hint-Updata')
      }, { // 实名认证 - 粘贴教程
        path: '/Hint-Paste',
        meta: {
          title: '实名认证 粘贴教程',
          // classify: ['setting', 'info']
        },
        component: () => import('@/Pages/My/Menu/Safety-Setting/Real-Name-Authentication/Hint-Paste')
      }], { // 我的页面 - 团队管理
        path: '/Team-Manage',
        meta: {
          title: '团队管理',
          type: 'menu',
        },
        component: () => import('@/Pages/My/Menu/Team-Manage'),
      },
    ],
    ...[ // 我的页面 - 更多菜单
      { // 我的页面 - 算力存储器
        path: '/Force-Storage',
        meta: {
          title: '算力存储器',
          type: 'moreMenu',
        },
        component: () => import('@/Pages/My/Move/Force-Storage'),
        // }, { // 我的页面 - 回馈活动
        //   path: '/Activity',
        //   meta: {
        //     title: '回馈活动',
        //     type: 'moreMenu',
        //   },
        //   component: () => import('@/Pages/My/Activity'),
        //   children: [{ // 活动详情
        //     path: '/Activity-View/:exchangeId',
        //     meta: {
        //       title: '活动详情',
        //     },
        //     component: () => import('@/Pages/My/Activity/Activity-View'),
        //   }, { // 活动购买
        //     path: '/Activity-Buy/:exchangeId',
        //     meta: {
        //       title: '活动购买',
        //     },
        //     component: () => import('@/Pages/My/Activity/Activity-Buy'),
        //   }]
      }, { // 我的页面 - 我的理财
        path: '/My-Momey-Manage',
        meta: {
          title: '我的基金',
          type: 'moreMenu',
        },
        component: () => import('@/Pages/My/Move/My-Momey-Manage'),
      }, { // 我的页面 - 客服咨询
        path: '/Service',
        meta: {
          title: '客服咨询',
          type: 'moreMenu',
        },
        component: () => import('@/Pages/My/Move/Service'),
      }, { // 我的页面 - 在线咨询
        path: '/Online-Consultant',
        meta: {
          title: '在线咨询',
        },
        component: () => import('@/Pages/My/Move/Service/Online-Consultant'),
      }, { // 我的页面 - 资讯
        path: '/News',
        meta: {
          title: '资讯 & 公告',
          type: 'moreMenu',
        },
        component: () => import('@/Pages/My/Move/News'),
      }, { // 我的页面 - 资讯 - 详情
        path: '/NewView/:id',
        meta: {
          title: '公告详情'
        },
        component: () => import('@/Pages/My/Move/News/View'),
      }
    ],
    ...[{
      path: '/Invite',
      meta: {
        title: '邀请好友',
        directory_level: 0,
      },
      component: () => import('@/Pages/My/Invite'),
    }]
  ]
},
// User
{
  path: '/User',
  component: () => import('@/Pages/User'),
  redirect: '/Login',
  children: [{ // 登陆
    path: '/Login',
    component: () => import('@/Pages/User/Login')
  }, { // 注册
    path: '/Register',
    component: () => import('@/Pages/User/Register')
  }, { // 忘记密码
    path: '/Forget-Password',
    component: () => import('@/Pages/User/Forget-Password')
  }]
},
// Activity
...[{
  path: '/Activity-Server-Migration',
  meta: {
    title: '服务器迁移',
    goBack: true
  },
  component: () => import('@/components/Activity/Activity-Server-Migration')
}],
// *
{
  path: '*',
  redirect: '/Home'
}
];

// 让没有设定 name 的路由 以路由地址 path 作为默认 name
routes.map((item) => routersMap(item));

function routersMap(item) {
  // 判断 路由中是否存在 name
  if (!item.name) {
    // 去除 路由参数
    let a = item.path.split('/:')[0];
    // 处理 路由信息中 path 第一个且为'/'的字符
    if (a.substr(0, 1) === '/')
      a = a.substr(1)
    item.name = a;
  }
  if (item.children) item.children.map((i) => routersMap(i));
}

export default routes
