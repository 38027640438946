import Vue from 'vue';
import router from '@/Vue-Router';
import axios from '@/Vue-Axios';
import $ from 'jquery' //在需要使用的页面中
import {
  Modal,
  message,
} from 'ant-design-vue'
message.config({
  top: `100px`,
  duration: 2,
  maxCount: 10,
});

Vue.use(Modal);

const api = (a, datas, fun = {}) => {
  fun;
  try {
    const btnEnabled = () => {
      $('.ant-form button').css({
        "pointer-events": "auto",
      }).removeAttr('disabled');
    }

    const btnDisable = () => {
      $('.ant-form button').css({
        "pointer-events": "none",
      }).attr('disabled', 'disabled');
    }

    const goBack = () => {
      setTimeout(() => {
        history.go(-1);
      }, 1500);
    }

    btnDisable();
    // POST
    switch (a) {
      case 'recharge':
      case 'withdraw':
      case 'transfer':
        message.warning("申请提交中。。。");
        axios
          .post(
            `personal-center/${a}`,
            axios.qs.stringify(datas)
          )
          .then((res) => {
            // 清空提示层
            message.destroy();
            if (res.code === 0) {
              message.success(`${
                                  a === 'recharge'
                                    ? '充币'
                                    : a === 'withdraw'
                                      ? '提币'
                                      : a === 'transfer'
                                        ? '转账'
                                        : ''
                                }申请已提交，请等待审核`);
              goBack();
            } else if (res.code === 10504) {
              Modal.warning({
                title: `您还没有进行实名认证`,
                icon: "warning", // <a-icon type="warning" />
                okText: "立即设置",
                onOk() {
                  router.push({
                    name: "Real-Name-Authentication",
                  });
                },
              });
            } else {
              btnEnabled();
              message.warning(res.message);
            }
          });
        break;
      case 'change-password':
        message.warning("修改请求提交中。。。");
        axios
          .post(
            "personal-center/change-password",
            axios.qs.stringify({
              category: datas.category,
              original: axios.Base64.encode(datas.original),
              recent: axios.Base64.encode(datas.recent),
              confirmed: axios.Base64.encode(datas.confirmed),
            })
          )
          .then((res) => {
            message.destroy();
            if (res.code === 0) {
              message.success(res.message);
              axios.get("logout", {
                type: 'change-password'
              });
            } else {
              console.log(res.code, res.message);
              message.warning(res.message);
            }
          });
        break;
    }
  } catch (err) {
    console.log('src/components/js/api.js', err)
  }
}

const Api = function () {
  const getDatas = function () {
    if (res.code === 0 && res.data)
      return res.data
    else
      return false
  }
  var res = {}

  return {
    post: function () {
      /** 初始化按钮，禁用或者开启按钮可点击状态，防止表单重复提交
        false: 禁用所有按钮
        true : 开启所有按钮
       */
      const setBtnDom = function (sw = null) {
        if (sw === null) return

        const a = [];
        const domBtn = [];

        a.push(...document.getElementsByClassName('ant-form'));
        a.push(...document.getElementsByClassName('ant-modal-footer'));
        a.map(item => [...item.getElementsByTagName('button')].map(item => domBtn.push(item)));

        const btn_enabled = () => {
          domBtn.map(item => {
            item.style.pointerEvents = "auto"
            item.removeAttribute('disabled');

            const dom_timeout = item.getElementsByClassName('timeout')[0];
            if (dom_timeout) dom_timeout.remove();
          });
        }

        const btn_disabled = (txt = '') => {
          domBtn.map(item => {
            item.style.pointerEvents = "none";
            item.setAttribute('disabled', 'disabled');

            if (txt === '') return;
            const dom_timeout = item.getElementsByClassName('timeout')[0];
            if (dom_timeout)
              dom_timeout.innerHTML = `(${txt}s)`
            else
              item.innerHTML = item.innerHTML + `<span class="timeout">(${txt}s)</span>`;
          });
        }

        if (typeof sw === 'number') {
          let timeout = parseInt(sw);
          const a = setInterval(() => {
            btn_disabled(timeout)
            if (0 < timeout--) return;
            clearInterval(a);
            btn_enabled();
          }, 1000)
        } else if (sw) btn_enabled()
        else btn_disabled()
      }
      // 返回上级
      const goBack = (time = 1500) => {
        setTimeout(() => {
          history.go(-1);
        }, time);
      }

      // const windowReload = (time = 1500) => {
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, time);
      // }

      /** 表单提交后返回
        res: 返回数据
       */
      const postThen = function (res) {
        if (res.code === 0) {
          // 清空提示层
          message.destroy();
          message.success(res.message);
          goBack();
        } else {
          setBtnDom(true);
        }
      }

      setBtnDom(false);

      return {
        changeWalletAddress: function (datas) {
          message.warning('表单提交中...');
          return axios
            .post(
              "personal-center/change-wallet-address",
              axios.qs.stringify(datas)
            )
            .then(postThen);
        },
        Login: function (datas) {
          message.warning("登录验证中。。。");

          return axios.post(
            "login",
            axios.qs.stringify({
              certificate: datas.admin,
              pwd: axios.Base64.encode(datas.password),
            })
          ).then(res => {
            if (!res) {
              setBtnDom(true);
            } else if (res.code === 0) {
              message.destroy();
              // 用户信息 存储
              sessionStorage.setItem("user", JSON.stringify(res.data));
              localStorage.setItem("user", JSON.stringify(res.data));
              message.success("登录成功，正在跳转");
              // windowReload(200);
              setTimeout(() => {
                router.push({
                  name: 'Home'
                })
              }, 500)
            } else {
              if (res.code === 10012)
                // 频繁登陆限制
                setBtnDom(60);
              else
                setBtnDom(true);
              // 用户信息 清除
              sessionStorage.removeItem("user");
              localStorage.removeItem("user");
            }
          });
        }
      }
    },
    get: function () {
      return {
        async getCurrencyInfo(datas) {
          res = await axios
            .get("personal-center/currency", {
              params: {
                ...datas
              },
            });

          return getDatas(res);
        },
        async getMiners(datas) {
          res = await axios
            .get("miners", {
              params: {
                ...datas
              }
            });

          return getDatas(res);
        },
        async getMiner(datas) {
          res = await axios
            .get("miner", {
              params: {
                ...datas
              }
            });

          return getDatas(res);
        },
        Logout() {
          axios.get("logout");
        }
      }
    },
    async getPublic(src, flag = false) {
      res = await axios
        .get(src, {
          params: {
            type: 'public'
          }
        });

      return flag & Boolean(res) ? res : Boolean(res);
    },
    async profile() {
      let user = JSON.parse(sessionStorage.getItem("user"));
      // 防止重复请求
      if (JSON.parse(sessionStorage.getItem('profile_state'))) return;
      sessionStorage.setItem('profile_state', true);
      res = await axios.get("profile");
      sessionStorage.removeItem('profile_state');


      if (res.code === 0) {
        const newUser = Object.assign(user, res.data)
        user = JSON.stringify(newUser);
        localStorage.setItem("user", user);
        sessionStorage.setItem("user", user);
      } else {
        localStorage.removeItem("user", user);
        sessionStorage.removeItem("user", user);
      }
      // console.log('res1', res, Boolean(res.code))
      // return re
    },
    // return Boolean(res.code);
  }
}

// api
Vue.prototype.$api = api;
Vue.prototype.$Api = new Api();