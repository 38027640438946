import Vue from 'vue';
import Router from 'vue-router';
import routes from './config';

Vue.use(Router);

const router = new Router({
  routes
});

/* 全局路由守卫 */
router.beforeEach((to, from, next) => {
  // console.log(from, to)
  let user = JSON.parse(sessionStorage.getItem("user"));

  if (user && (from.matched[0] && from.matched[0].name !== 'User')) {
    Vue.prototype.$Api.profile(true);
  } else if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"))
  }

  // token 值存在，进入页面
  if (user && user.token) {
    // // 如果页面 将要 进入 登录、注册等其他页面，则自动跳转 首页
    to.name === undefined || (to.matched[0] && to.matched[0].name === 'User') ?
      next({
        path: '/Home'
      }) :
      next();
  } else {
    // 如果 token 不存在，并且跳转路由不是朝向注册页，则跳转登录页面
    (to.matched[0] && to.matched[0].name !== 'User') ?
    next({
      name: 'Login',
    }): next();
  }
});

export default router;